<template>
  <div class="hello">
    <div :style='headerImg' class="headerImg">
      <img :src="ifShowIOSQRCode?require('../assets/home/ios_qrcode.png'):require('../assets/home/android_qrcode.png')" class="qrCode"/>
      <div :class="ifShowIOSQRCode?'appStore active':'appStore'" @click="showIOSQRCode"/>
      <div :class="ifShowAndroidQRCode?'android active':'android'" @click="showAndroidQRCode"/>
    </div>
    <div class="about">
      <img src="@/assets/home/img_jieshao.png" class="aboutImg">
      <div class="aboutContent">
        <div class="aboutContentLeft">
          <span>
            「剁手记」是一个以优惠返利模式为基础的社交电商APP，
如果你是普通电商购物用户，通过剁手记下单各大电商平台的商品可以获得隐藏优惠券和返利，预计每年可以节省网购支出的10-20%。更为有趣的是，不管作为普通电商用户或者流量主(比如实体店，渠道商，Up主)，
您都可以通过剁手记免费提供的虚拟网店将您现有用户和流量转化为您的电商顾客流量，从而增加和扩展您的流量变现能力。
我们相信经营的本质是经营流量和人群，充分利用流量产生价值是未来商业提升利润的突破点之一，
未来的商业可能不是餐馆只卖餐饮，服装店只卖服装，而是互相引流实现利润分配的充分性和完整性。
我们希望您和我们一起为之尝试和探索！
          </span>
        </div>
        <div>
          <video src="https://duoshouji-test.oss-cn-shanghai.aliyuncs.com/video/2.0/DSJ%20Movie.mp4" poster="@/assets/home/poster.jpg" controls="" data-video="4">在浏览器中打开</video>
        </div>
      </div>
    </div>
    <div class="why">
      <span>为什么我们的产品与众不同？</span>
      <img src="@/assets/home/img_hu.png">
      <div class="whyContent">
        <div class="whyItem">
          <img src="@/assets/home/icon_duopingtai.png">
          <p>多平台合作</p>
          <p>淘宝、京东、拼多多等大型电商平台合作，优质商品有保障</p>
        </div>
        <div class="whyItem">
          <img src="@/assets/home/icon_youhui.png">
          <p>大优惠多返现</p>
          <p>为用户提供大额优惠券及红包确认收获后即可获返现奖励</p>
        </div>
        <div class="whyItem">
          <img src="@/assets/home/icon_tixian.png">
          <p>提现秒入帐</p>
          <p>为用户提供大额优惠券及红包确认收获后即可获返现奖励</p>
        </div>
        <div class="whyItem">
          <img src="@/assets/home/icon_shiti.png">
          <p>助力实体经济</p>
          <p>提供专业的服务和物料帮助实体经济与电商巧妙结合增加收入</p>
        </div>
        <div class="whyItem">
          <img src="@/assets/home/icon_liuliang.png">
          <p>助力流量主</p>
          <p>为流量主解决虽有流量但变现难收益期端的问题</p>
        </div>
      </div>
    </div>
    <div class="help">
      <div class="helpContent">
        <div class="helpContentText">
          <div class="row">
            <img src="@/assets/home/icon_fuzhu.png" alt="">
            <div class="helpContentTextTitle">社交电商助力流量变现</div>
          </div>
          <div class="helpContentTextContent">互联网时代，得流量者得天下，您朋友圈乃至粉丝圈均属您的流量，而剁手记能以更快、更加直接地方式助您流量变现。通过邀请好友使用剁手记，您就可获取推广佣金；当好友得返现后，您也可获得奖励；当别人通过您分享的优惠信息下单后，您还能得到奖励。社交电商一大特点是每个人不仅是消费者，也是店主。流量创造价值，剁手记助您更快实现流量变现，快快利用您的流量，轻松薅羊毛，躺着就能赚!</div>
        </div>
        <img class="helpContentImg" src="@/assets/home/img_zhulibianxian.png">
      </div>
      <div class="helpContent">
        <img class="helpContentImg1" src="@/assets/home/img_fanli.png">
        <div class="helpContentText1">
          <div class="row">
            <img src="@/assets/home/icon_fuzhu.png" alt="">
            <div class="helpContentTextTitle1">返利</div>
          </div>
          <div class="helpContentTextContent">剁手记是一个网购返利神器，您可以在剁手记内搜索淘宝京东天猫拼多多商品获取优惠券和红包，也可以在淘宝京东拼多多APP内选好商品后再使用口令查看商品优惠券和返利信息.</div>
        </div>
      </div>
      <div class="feature">
        <span class="featureTile">核心特色</span>
        <img class="featureTileImg" src="@/assets/home/img_hu.png">
        <div class="featureContent">
          <div class="contentLeft">
            <div class="contentLeftItem1" @mouseover="updateCoreImage('img_youhuiquan')">
              <div class="contentLeftItem1Text">
                <div class="contentLeftItem1TextTitle">优惠券</div>
                <div class="contentLeftItem1TextBody">一键领取店铺隐藏大额优惠券，查询天猫、淘宝、京东、拼多多、唯品会等众多平台优惠券以及商品佣金</div>
              </div>
              <img class="contentLeftItem1Img" src="@/assets/home/icon_youhuiquan.png">
            </div>
            <div class="contentLeftItem1" @mouseover="updateCoreImage('img_duoshouquan')">
              <div class="contentLeftItem1Text">
                <div class="contentLeftItem1TextTitle">剁友圈</div>
                <div class="contentLeftItem2TextBody">淘宝天猫京东拼多多等商城精选好物、爆款推荐，朋友圈宣传素材，可全网分享。</div>
              </div>
              <img class="contentLeftItem1Img" src="@/assets/home/icon_duoshouquan.png">
            </div>
            <div class="contentLeftItem1" @mouseover="updateCoreImage('img_renwu')">
              <div class="contentLeftItem1Text">
                <div class="contentLeftItem1TextTitle3">做任务领奖励</div>
                <div class="contentLeftItem3TextBody">多重任务奖励，完成任务轻松领取额外奖励收益，分享越多，收益越多。</div>
              </div>
              <img class="contentLeftItem1Img" src="@/assets/home/icon_renwu.png">
            </div>
          </div>
          <img class="contentMid" :src="coreImage">
          <div class="contentRight">
            <div class="contentRightItem1" @mouseover="updateCoreImage('img_huiyuanzhongxin')">
              <img class="contentLeftItem2Img" src="@/assets/home/icon_huiyuanzhongxin.png">

              <div class="contentLeftItem2Text">
                <div class="contentLeftItem1TextTitle1">会员中心</div>
                <div class="contentLeftItem1TextBody contentLeftItem4TextBody">开通超级店主，享受积分奖励及分享获推广奖励等个性化定制海量特权</div>
              </div>
            </div>
            <div class="contentRightItem1" @mouseover="updateCoreImage('img_xinshou')">
              <img class="contentLeftItem2Img" src="@/assets/home/icon_xinshoujiaoxue.png">

              <div class="contentLeftItem2Text">
                <div class="contentLeftItem1TextTitle1">新手教学</div>
                <div class="contentLeftItem2TextBody">新手教程、分享小技巧、剁手记使用介绍，新手一看就懂，简单易学，方便快捷，轻轻松松省钱赚钱</div>
              </div>
            </div>
            <div class="contentRightItem1"  @mouseover="updateCoreImage('img_shouyi')">
              <img class="contentLeftItem2Img" src="@/assets/home/icon_renwu.png">

              <div class="contentLeftItem2Text">
                <div class="contentLeftItem1TextTitle1">收益线报</div>
                <div class="contentLeftItem3TextBody contentLeftItem4TextBody">收益清晰可查，个人收益，网店收益，奖励收益以及直邀或间邀剁友团队人数，预估收益等详情</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="corporation">
      <span>合作平台</span>
      <img src="@/assets/home/img_hu.png">
      <div class="corporationContent">
        <div v-for="(item, index) in 33" v-bind:key="index"> 
          <img :src="getUrl(item)" alt="" @mouseover="selectUrl(item)" :id="item" @mouseleave="unselectUrl(item)">
          <img :src="getActiveUrl(item)" style="postion:absolute;top:-2px;left:-2px;width:1px;height:1px">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'Home',
  props: {
    msg: String
  },
  data() {
     return{
      ifShowIOSQRCode: true,
      ifShowAndroidQRCode: false,
      coreImage: require('../assets/home/img_youhuiquan.png')
    }
  },
  setup() {
    const headerImg = reactive({
        'height': '678px',
        'width':'1920px',
    })
    // let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // let height = w/1920*678
    // headerImg.width = `${w}px`
    // headerImg.height = `${height}px`
    return{
      headerImg
    }
  },
  methods: {
    getUrl: (index)=>{
      return require('../assets/corporation/'+index+'.png');
    },
    getActiveUrl:  (index)=>{
      return require('../assets/corporation/'+index+'a.png');
    },
    selectUrl: (index)=>{
      document.getElementById(index).src = require('../assets/corporation/'+index+'a.png');
    },
    unselectUrl: (index)=>{
      document.getElementById(index).src = require('../assets/corporation/'+index+'.png');
    },
    showIOSQRCode(){
      this.ifShowIOSQRCode = true;
      this.ifShowAndroidQRCode = false;
    },
    showAndroidQRCode(){
      this.ifShowIOSQRCode = false;
      this.ifShowAndroidQRCode = true;
    },
    updateCoreImage(name){
      this.coreImage = require('../assets/home/'+name+'.png');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerImg{
  height: 500px;
  width: 1440px;
  background-image: url(../assets/home/img_banner.png);
  background-size: contain;
  position: relative; 
}
.appStore{
  position: absolute;
  top: 3.92rem;
  left: 5.81rem;
  width: 2.7rem;
  height: 0.66rem;
  background-image: url(../assets/home/but_apple_nor.png);
  background-size: cover;
  cursor: pointer;
}
.appStore:active, .appStore.active{
  position: absolute;                
  background-image: url(../assets/home/but_apple_sle.png);
  width: 2.86rem;
  top:3.89rem;
  left:5.73rem;
  height: 0.82rem;
  background-size: cover;

}
.android{
  position: absolute;
  top: 5.01rem;
  left: 5.81rem;
  width: 2.7rem;
  height: 0.66rem;
  background-image: url(../assets/home/but_android_nor.png);
  background-size: cover;
  cursor: pointer;
}
.android:active, .android.active{
  position: absolute;
  top:4.98rem;
  width: 2.86rem;
  left:5.73rem;
  height: 0.82rem;
  background-image: url(../assets/home/but_android_sel.png);

}

.qrCode{
  position: absolute;
  top: 4.01rem;
  left: 3.21rem;
  width: 1.69rem;
}
.about{
  height: 7.68rem;
  flex-direction: column;
  align-items:center;
}
.aboutImg{
  width: 1.43rem;
  margin-top: 1rem;
}
.aboutContent{
    /* margin: 0 3.2rem; */
    /* margin-top: .88rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}
.aboutContentLeft{
  margin-left: 3.2rem;
  width: 5rem;
  text-align: left;
  color: #333333;
  font-size: 18px;
}

.aboutContent>img{
  margin-left: 3.2rem;
  width:4.4rem ;
  height:3.6rem
}
.aboutContent video{
  width: 6rem;
  height: 3.5rem;
  margin-right: 3.2rem;
}
.why,.corporation{
  background-color: #FDF9F9;
  height: 7.68rem;
  display: flex;
  flex-direction: column;
  align-items:center;
}
.corporation {
  background-color: #fff;
}
.corporationContent {
    display: flex;
    flex-direction: column;
    height: 410px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 1308px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.corporationContent img {
  width: 190px;
  height: 100px;
  background-color: #FAFAFA;
  cursor: pointer;
}
.corporationContent div {
  width: 190px;
  height: 100px;
  background-color: #FAFAFA;
  margin: 6px 14px;
}
.why>span, .corporation>span{
  margin-top: 1.4rem;
  width: 364px;
  height: 41px;
  font-size: 28px;
  
  font-weight: 500;
  color: #333333;
  line-height: 41px;
}
.whyContent{
  margin-top: 1.32rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 12.8rem;
}
.whyItem{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.whyItem>img{
  width: .68rem;
  height: .64rem;
}
.whyItem :nth-child(2){
  margin-top: .67rem;
  width: 220px;
  height: 33px;
  font-size: 22px;
  
  font-weight: 500;
  color: #333333;
  line-height: 33px;
}
.whyItem :nth-child(3){
  margin-top: .33rem;
  width: 220px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.helpContent{
  width: 100%;
  display: flex;
  flex-direction: row; 
}
.helpContent2{
  width: 100%;
  display: flex;
  flex-direction: row; 
}
.helpContentText{
  margin-top: 2.45rem;
  margin-left: 3.2rem; 
}
.helpContentText1{
  margin-top: 3.77rem;
  margin-left: .75rem; 
}
.helpContentTextTitle{
  margin-left: .14rem;
  width: 2.2rem;
  height: .26rem;
  font-size: .22rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .36rem;
}
.helpContentTextTitle1{
  margin-left: .14rem;
  height: .26rem;
  font-size: .22rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .36rem;
}
.row{
  display: flex;
  flex-direction: row;
}
.helpContentTextContent{
  margin-left: .14rem;
  margin-top: .31rem;
  height: 1.9rem;
  width: 6.72rem;
  font-size: .16rem;
  font-weight: 400;
  color: #666666;
  line-height: .38rem;
  text-align: left;
}

.help{
  width: 19.2rem;
  height: 23.04rem;
  display: flex;
  flex-direction: column;
  align-items:center;
}
.helpContentImg{
  width: 5.23rem;
  height: 3.92rem;
  margin-top: 1.8rem;
  margin-left: .63rem;
}
.helpContentImg1{
  width: 4.96rem;
  height: 3.11rem;
  margin-top: 3.23rem;
  margin-left: 3.2rem;
}
.feature{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.44rem;
  height: 8.54rem;
  width: 19.2rem;
}
.featureTile{
  height: .41rem;
  font-size: .28rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .41rem;
}
.featureTileImg{
  width: 19px;
  height: 4px;
}
.featureContent{
  display: flex;
  flex-direction: row;
  margin-top: .57rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-top: .74rem;
  /* width: 19.2rem; */
  height: 6.83rem;
  /* background: 6.83rem 6.83rem url(../assets/home/bg_hexintese.png) no-repeat top; */
  background:url(../assets/home/bg_hexintese.png);
  background-size:6.83rem 6.83rem;
  background-repeat:no-repeat;
  background-position: top;
}
.contentLeft{
  /* background-color: red; */
  width: 4.81rem;
  height: 4.8rem;

}
.contentRight{
  /* background-color:green; */
  width: 4.81rem;
  height: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contentMid{
  /* background-color: blue; */
  width: 2.34rem;
  height: 4.85rem;
  margin-right: .42rem;
  margin-left: .42rem;
}
.contentLeftItem1{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.6rem;
  padding: 0 10px;
}
.contentLeftItem1:hover{
    box-shadow: 0px 7px 14px 4px rgb(60 60 60 / 18%);
    border-radius: 6px;
}
.contentRightItem1{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.6rem;
  padding: 0 10px;
}
.contentRightItem1:hover{
    box-shadow: 0px 7px 14px 4px rgb(60 60 60 / 18%);
    border-radius: 6px;
}
.contentLeftItem1TextTitle{
  width: .54rem;
  height: .2rem;
  font-size: .18rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .2rem;
  margin-bottom: .08rem;
}
.contentLeftItem1TextTitle3{
  width: 1.08rem;
  height: .2rem;
  font-size: .18rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .2rem;
  margin-bottom: .08rem;
}
.contentLeftItem1TextBody{
  width: 3.5rem;
  height: .5rem;
  font-size: .14rem;
  font-weight: 400;
  color: #666666;
  line-height: .2rem;
  text-align: left;
}
.contentLeftItem2TextBody{
  width: 2.57rem;
  height: .4rem;
  font-size: .14rem;
  font-weight: 400;
  color: #666666;
  line-height: .2rem;
}
.contentLeftItem3TextBody{
  width: 3.3rem;
  height: .53rem;
  font-size: .14rem;
  font-weight: 400;
  color: #666666;
  line-height: .2rem;
  text-align: left;
}
.contentLeftItem4TextBody{
  text-align: right;
}
.contentLeftItem1Img{
  height: .48rem;
  width: .48rem;
  margin-left: .69rem;
}
.contentLeftItem2Img{
  height: .48rem;
  width: .48rem;
  margin-right: .69rem;
}
.contentLeftItem2Text{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.contentLeftItem1TextTitle1{

  font-size: .18rem;
  
  font-weight: 500;
  color: #333333;
  line-height: .2rem;
  margin-bottom: .08rem;
}
</style>


